.blogsCard {
  border-radius: 0.8vw;
  padding: 2% 3%;
  width: 90%;
  margin-top: 3%;
}

.blogPrimaryText {
  font-family: "Ubuntu", sans-serif;
  color: white;
  font-size: 1.8vw;
}

.optionIcons {
  color: white;
  font-size: 1.8vw;
}

.blogSecondaryText {
  font-size: 1.1vw;
  text-align: left;
}

.blogFooter {
  margin-top: 20%;
  width: 100%;
}

.blogImage {
  height: 3vw;
  width: 3.5vw;
  margin-top: 2%;
}

/* All media quiries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .blogsCard {
    width: 100%;
    margin-top: 5%;
  }

  .blogPrimaryText {
    font-size: 5vw;
  }

  .optionIcons {
    font-size: 5vw;
  }

  .blogSecondaryText {
    font-size: 3vw;
  }

  .blogImage {
    height: 10vw;
    width: 15vw;
  }
}

/* For mobile screens*/
@media screen and (max-width: 600px) {
  .blogsCard {
    width: 100%;
    margin-top: 5%;
  }

  .blogPrimaryText {
    font-size: 5vw;
  }

  .optionIcons {
    font-size: 5vw;
  }

  .blogSecondaryText {
    font-size: 3vw;
  }

  .blogImage {
    height: 10vw;
    width: 15vw;
  }
}
