body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.contentInfo {
  padding: 0% 5%;
  height: 100vh;
  max-height: 100vh;
  position: relative;
  text-align: center;
}

.verticalCenter {
  margin: 0;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.myName {
  font-size: 7vw;
  font-weight: 600;
  font-family: "Righteous", cursive;
  color: #757575;
}

.myProfession {
  font-size: 2vw;
  font-family: "Righteous", cursive;
  font-weight: 600;
  margin-top: -3%;
  color: #e2257d;
}

.myIntro {
  font-size: 1.2vw;
  color: #a9a9a9;
  padding: 0% 8%;
}

.buttonContact {
  font-size: 1.7vw;
  margin-top: 4%;
  border-radius: 0.5vw;
  cursor: pointer;
  color: white;
  border: none;
  padding: 2%;
  font-family: "Spinnaker", sans-serif;
  -webkit-transition: font-size 0.3s, box-shadow 0.3s;
  transition: font-size 0.3s, box-shadow 0.3s;
  background-image: -webkit-linear-gradient(
    left,
    #fd898e,
    #f97a91,
    #f26c96,
    #e95f9d,
    #dd54a5
  );
  background-image: linear-gradient(
    to right,
    #fd898e,
    #f97a91,
    #f26c96,
    #e95f9d,
    #dd54a5
  );
}

.buttonContact:hover {
  font-size: 1.8vw;
  box-shadow: 0px 30px 15px -20px #f4b5da;
}

.downloadResume {
  font-size: 1.2vw;
  margin-top: 1%;
  text-decoration: none;
  color: gray;
  -webkit-transition: border-bottom 0.3s;
  transition: border-bottom 0.3s;
  border-bottom: 1px solid rgb(211, 211, 211, 0);
}

.downloadResume:hover {
  border-bottom: 1px solid rgb(211, 211, 211, 1);
  cursor: pointer;
}

.socialIconsDiv {
  margin-top: 4%;
  margin-left: -9%;
}

.socialIcon {
  font-size: 4vw;
  margin-left: 9%;
  cursor: pointer;
  -webkit-transition: color 0.3s, font-size 0.3s;
  transition: color 0.3s, font-size 0.3s;
  color: #757575;
}

.socialIcon:hover {
  color: #e2257d;
}

.sideBackgroundImage {
  width: 70%;
  min-height: 100%;
  height: 100vh;
  background-image: url("https://s3.us-east-2.amazonaws.com/victordeb.me-files/images/me.jpg");
  background-position: center;
  background-size: cover;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .contentInfo {
    width: 100%;
  }

  .myName {
    font-size: 15vw !important;
  }

  .myProfession {
    font-size: 5vw !important;
  }

  .myIntro {
    font-size: 4vw !important;
  }

  .buttonContact {
    font-size: 6vw !important;
  }

  .buttonContact:hover {
    font-size: 6.5vw !important;
  }

  .downloadResume {
    font-size: 4vw !important;
  }

  .socialIcon {
    font-size: 12vw !important;
  }

  .sideImage {
    display: none;
  }
}

/* For all size of mobile screens, screen width 600px or smaller */

@media screen and (max-width: 600px) {
  .myName {
    font-size: 15vw !important;
  }

  .myProfession {
    font-size: 5vw !important;
  }

  .myIntro {
    font-size: 4vw !important;
  }

  .buttonContact {
    font-size: 6vw !important;
  }

  .buttonContact:hover {
    font-size: 6.5vw !important;
  }

  .downloadResume {
    font-size: 4vw !important;
  }

  .socialIcon {
    font-size: 12vw !important;
  }
}

.navBar {
  background-color: #30364a;
  box-shadow: 2px 0px 20px grey;
  width: 5vw;
  padding: 0% 0.3%;
  position: relative;
}

.hamburgerButton {
  font-size: 4vw;
}

.sideBarIdentifier {
  top: 5px;
  color: #e2257d;
  position: relative;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes example {
  0% {
    left: 0px;
  }
  25% {
    left: 5px;
  }
  50% {
    left: 10px;
  }
  75% {
    left: 5px;
  }
  100% {
    left: 0px;
  }
}

@keyframes example {
  0% {
    left: 0px;
  }
  25% {
    left: 5px;
  }
  50% {
    left: 10px;
  }
  75% {
    left: 5px;
  }
  100% {
    left: 0px;
  }
}

.navBarItems {
  position: relative;
  top: 40%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.icon {
  margin-top: 10px;
  width: 4vw;
  height: 8vh;
}

.menuItem {
  text-decoration: none;
  line-height: 1vh;
  margin-top: 1vh;
  cursor: pointer;
  color: #afa7a7;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.menuItem:hover {
  color: white;
}

.menuIcon {
  font-size: 2vw;
}

.menuName {
  font-size: 0.9vw;
}

.active {
  color: white !important;
  cursor: no-drop;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .navBar {
    box-shadow: 2px 0px 20px grey;
    width: 14vw;
    padding: 0% 0.3%;
  }

  .menuIcon {
    font-size: 5vw;
  }

  .menuItem {
    line-height: 1.3;
  }

  .menuName {
    margin-top: 50px;
    font-size: 2.7vw;
  }
  .icon {
    margin-top: 10px;
    width: 13vw;
    height: 9vh;
  }
}

/* For mobile screens*/
@media screen and (max-width: 600px) {
  .navBar {
    box-shadow: 2px 0px 20px grey;
    width: 15.2vw;
    padding: 0% 0.3%;
  }

  .menuItem {
    line-height: 0.3;
  }

  .menuIcon {
    font-size: 5vw;
  }

  .menuName {
    font-size: 2.7vw;
  }
  .icon {
    margin-top: 10px;
    width: 13vw;
    height: 7vh;
  }
}

.main {
  margin-top: 1%;
}

.progressBarParent {
  font-size: 0.9vw;
  background-color: #f1f1f1;
  border-radius: 15px;
  margin-left: 6%;
  margin-top: 1%;
}

.progressBarChild {
  -webkit-transition: width 2s ease-out;
  transition: width 2s ease-out;
  font-family: "Fredoka One", cursive;
  color: white;
  border-radius: 15px;
}

.logo {
  height: 2.3vw;
  width: 2.3vw;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .main {
    margin-top: 1.5%;
  }

  .progressBarParent {
    font-size: 2.5vw;
    margin-left: 8%;
    margin-top: 1%;
  }

  .logo {
    height: 5vw;
    width: 5vw;
  }
}

/* For all size of mobile screens, screen width 600px or smaller */

@media screen and (max-width: 600px) {
  .main {
    margin-top: 1.5%;
  }

  .progressBarParent {
    font-size: 2.5vw;
    margin-left: 8%;
    margin-top: 1%;
  }

  .logo {
    height: 5vw;
    width: 5vw;
  }
}

.contentInfoSkills {
  margin-left: 12%;
  text-align: center;
  height: 100vh;
  max-height: 100vh;
  position: relative;
  text-align: center;
}

.verticalMiddleSkills {
  margin: 0;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.headerText {
  font-size: 3vw;
  font-weight: 600;
  font-family: "Righteous", cursive;
  color: #757575;
}

.tagLine {
  color: #ed6b69;
  font-size: 1.5vw;
}

.techChart {
  margin-top: 3%;
}

.sideImageSkills {
  width: 75%;
  height: 100vh;
}

.sideBackgroundImageSkills {
  width: 80%;
  min-height: 100%;
  height: 100vh;
  background-image: url("https://s3.us-east-2.amazonaws.com/victordeb.me-files/images/freddie.jpg");
  background-position: center;
  background-size: cover;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .w3-half {
    width: 100% !important;
  }

  .headerText {
    font-size: 12vw;
  }

  .tagLine {
    font-size: 3.8vw;
  }

  .techStack {
    margin-top: 5%;
  }

  .techChart {
    margin-top: 5%;
  }

  .contentInfoSkills {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }

  .sideImageSkills {
    display: none !important;
  }
}

/* For all size of mobile screens, screen width 600px or smaller */

@media screen and (max-width: 600px) {
  .headerText {
    font-size: 12vw;
  }

  .tagLine {
    font-size: 3.8vw;
  }

  .techStack {
    margin-top: 5%;
  }

  .techChart {
    margin-top: 5%;
  }

  .contentInfoSkills {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }

  .verticalMiddleSkills {
    margin: 0;
    position: relative;
    top: 10%;
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    -ms-transform: translateY(-10%);
  }
}

.projectsSection {
  margin-top: 3%;
}

.projectImage {
  height: 20vw;
  width: 32vw;
}

.projectName {
  font-size: 2.5vw;
  font-weight: 600;
  font-family: Righteous, cursive;
  margin-left: 5%;
}

.iconProject {
  cursor: pointer;
  color: gray;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.iconProject:hover {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: #e2257d;
}

.projectDescription {
  font-size: 1.1vw;
  color: gray;
  text-align: justify;
  width: 81%;
  margin-left: 5%;
  line-height: 1.4;
}

.projectTechStack {
  margin-left: 3%;
  margin-top: 2%;
}

.techImages {
  height: 2vw;
  width: 2vw;
  margin-left: 2%;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .projectImage {
    height: 40vw;
    width: 64vw;
  }

  .projectName {
    font-size: 7vw;
  }

  .projectDescription {
    font-size: 4vw;
    width: 90%;
  }

  .techImages {
    height: 8vw;
    width: 8vw;
  }
}

/* For all size of mobile screens, screen width 600px or smaller */

@media screen and (max-width: 600px) {
  .projectImage {
    height: 40vw;
    width: 64vw;
  }

  .projectName {
    font-size: 7vw;
  }

  .projectDescription {
    font-size: 4vw;
    width: 90%;
  }

  .techImages {
    height: 8vw;
    width: 8vw;
  }
}

.contentInfoFolio {
  margin-left: 12%;
  text-align: center;
  height: 100vh;
  max-height: 100vh;
  position: relative;
}

.verticalMiddlePortfolio {
  margin: 0;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.headerTextPortfolio {
  font-size: 3vw;
  font-weight: 600;
  font-family: "Righteous", cursive;
  color: #757575;
}

.tagLinePortfolio {
  color: #74c3c8;
  font-size: 1.5vw;
}

.sideImagePortfolio {
  width: 80%;
  min-height: 100%;
  height: 100vh;
  background-image: url("https://s3.us-east-2.amazonaws.com/victordeb.me-files/images/lenon.jpg");
  background-position: center;
  background-size: cover;
}

.navButton {
  font-size: 1.2vw;
  border-radius: 0.5vw;
  cursor: pointer;
  color: white;
  border: none;
  padding: 1%;
  font-family: "Spinnaker", sans-serif;
  -webkit-transition: font-size 0.3s, box-shadow 0.3s;
  transition: font-size 0.3s, box-shadow 0.3s;
  background-image: -webkit-linear-gradient(
    left,
    #fd898e,
    #f97a91,
    #f26c96,
    #e95f9d,
    #dd54a5
  );
  background-image: linear-gradient(
    to right,
    #fd898e,
    #f97a91,
    #f26c96,
    #e95f9d,
    #dd54a5
  );
}

.navButton:hover {
  font-size: 1.3vw;
  box-shadow: 0px 30px 15px -20px #f4b5da;
}

.disableNavButton {
  background-image: -webkit-linear-gradient(left, #b0b0b0, #888888);
  background-image: linear-gradient(to right, #b0b0b0, #888888);
  cursor: no-drop;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .w3-half {
    width: 100% !important;
  }

  .headerTextPortfolio {
    font-size: 12vw;
  }

  .tagLinePortfolio {
    font-size: 3.8vw !important;
  }

  .contentInfoFolio {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }

  .navButton {
    margin-top: 2%;
    font-size: 4.5vw;
  }

  .navButton:hover {
    font-size: 4.9vw;
  }

  .sideImagePortfolio {
    display: none;
  }
}

/* For all size of mobile screens, screen width 600px or smaller */

@media screen and (max-width: 600px) {
  .headerTextPortfolio {
    font-size: 12vw;
  }

  .tagLinePortfolio {
    font-size: 3.8vw !important;
  }

  .contentInfoFolio {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }

  .verticalMiddlePortfolio {
    margin: 0;
    position: relative;
    top: 10%;
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    -ms-transform: translateY(-10%);
  }

  .navButton {
    margin-top: 5%;
    font-size: 4.5vw;
  }

  .navButton:hover {
    font-size: 4.9vw;
  }
}

.customCard {
  width: 100%;
  padding: 2% 2%;
  border-radius: 1vw;
  box-shadow: 5px 5px 10px #bdc3c7;
  margin-top: 3%;
}

.jobHeading {
  font-family: "Nunito", sans-serif;
  font-size: 1vw;
  color: purple;
  font-weight: 400;
}

.timeline {
  clear: both;
  font-family: "Nunito", sans-serif;
  font-size: 0.7vw;
  color: black;
  border-bottom: 4px solid purple;
}

.jobDescription {
  clear: both;
  text-align: left;
  padding-right: 5%;
  font-size: 1vw;
  line-height: 1.3;
  color: black;
}

.images {
  width: 9vw;
  height: 9vw;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .jobHeading {
    font-size: 3.5vw;
  }

  .timeline {
    font-size: 2.5vw;
  }

  .jobDescription {
    font-size: 2.5vw;
  }

  .images {
    width: 20vw;
    height: 20vw;
  }
}

/* For mobile screens*/
@media screen and (max-width: 600px) {
  .jobHeading {
    font-size: 3.5vw;
  }

  .timeline {
    margin-top: 2%;
    font-size: 2.5vw;
    margin-bottom: 2%;
  }

  .jobDescription {
    font-size: 3.2vw;
    line-height: 1.5;
  }

  .images {
    width: 20vw;
    height: 20vw;
  }

  .customCard {
    padding: 2% 2%;
    box-shadow: 5px 5px 10px #bdc3c7;
    margin-top: 6%;
  }
}

.contentInfoExp {
  margin-left: 12%;
  width: 100%;
  text-align: center;
  height: 100vh;
  max-height: 100vh;
  position: relative;
}

.verticalMiddleExperience {
  margin: 0;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.headerTextExp {
  font-size: 3vw;
  font-weight: 600;
  font-family: "Righteous", cursive;
  color: #757575;
}

.tagLineExp {
  color: #642520;
  font-size: 1.5vw;
}

.jobCards {
  margin-left: 6%;
}

.sideImageExp {
  width: 80%;
  min-height: 100%;
  height: 100vh;
  background-image: url("https://s3.us-east-2.amazonaws.com/victordeb.me-files/images/SG.jpg");
  background-position: center;
  background-size: cover;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .w3-half {
    width: 100% !important;
  }

  .headerTextExp {
    font-size: 10vw;
  }

  .tagLineExp {
    font-size: 3.8vw !important;
  }

  .jobCards {
    margin-left: 0%;
  }

  .contentInfoExp {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }

  .sideImageExp {
    display: none;
  }
}

/* For mobile screens*/
@media screen and (max-width: 600px) {
  .headerTextExp {
    font-size: 10vw;
  }

  .tagLineExp {
    font-size: 3.8vw !important;
  }

  .jobCards {
    margin-left: 0%;
  }

  .contentInfoExp {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }

  .verticalMiddleExperience {
    margin: 0;
    position: relative;
    top: 10%;
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    -ms-transform: translateY(-10%);
  }
}

.contentInfoEdu {
  margin-left: 12%;
  width: 100%;
  text-align: center;
  height: 100vh;
  max-height: 100vh;
  position: relative;
}

.verticalMiddleEducation {
  margin: 0;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.headerTextEdu {
  font-size: 3vw;
  font-weight: 600;
  font-family: "Righteous", cursive;
  color: #757575;
}

.tagLineEdu {
  color: gray;
  font-size: 1.5vw;
}

.sideImageEdu {
  width: 60%;
  min-height: 100%;
  height: 100vh;
  background-image: url("https://s3.us-east-2.amazonaws.com/victordeb.me-files/images/lesPaul_2.jpg");
  background-position: center;
  background-size: cover;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .w3-half {
    width: 100% !important;
  }

  .contentInfoEdu {
    margin-top: 10%;
  }

  .headerTextEdu {
    font-size: 10vw;
  }

  .tagLineEdu {
    font-size: 3.8vw !important;
  }

  .contentInfoEdu {
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }

  .sideImageEdu {
    display: none;
  }
}

/* For mobile screens*/
@media screen and (max-width: 600px) {
  .contentInfoEdu {
    margin-top: 10%;
  }

  .headerTextEdu {
    font-size: 10vw;
  }

  .tagLineEdu {
    font-size: 3.8vw !important;
  }

  .contentInfoEdu {
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }
}

.educationCard {
  padding: 2%;
  margin-top: 2%;
}

.educationContent {
  padding: 4%;
  border-radius: 1vw;
}

.educationPicture {
  width: 19vw;
  height: 16vw;
}

.educationDegree {
  color: #e2257d;
  font-size: 1.3vw;
  font-weight: 600;
}

.educationCollege {
  color: #757575;
  font-size: 1.2vw;
}

.educationDescription {
  text-align: left !important;
  margin-top: 1%;
  font-size: 1vw;
  color: #a9a9a9;
  margin-bottom: 4%;
}

.buttonConvo {
  text-decoration: none;
  margin-left: -42%;
  border-radius: 6px;
  font-size: 1.4vw;
  cursor: pointer;
  color: white;
  border: none;
  padding: 2%;
  font-family: "Spinnaker", sans-serif;
  -webkit-transition: font-size 0.3s, box-shadow 0.3s;
  transition: font-size 0.3s, box-shadow 0.3s;
  background-image: -webkit-linear-gradient(
    left,
    #fd898e,
    #f97a91,
    #f26c96,
    #e95f9d,
    #dd54a5
  );
  background-image: linear-gradient(
    to right,
    #fd898e,
    #f97a91,
    #f26c96,
    #e95f9d,
    #dd54a5
  );
}

.buttonConvo:hover {
  font-size: 1.5vw;
  box-shadow: 0px 25px 10px -15px #f4b5da;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .educationPicture {
    width: 40vw;
    height: 36vw;
  }

  .educationDegree {
    font-size: 5vw;
  }

  .educationCollege {
    font-size: 4vw;
  }

  .educationDescription {
    font-size: 4.5vw;
  }

  .buttonConvo {
    font-size: 5vw;
  }

  .buttonConvo:hover {
    font-size: 5.5vw;
  }
}

/* For mobile screens*/
@media screen and (max-width: 600px) {
  .educationPicture {
    width: 40vw;
    height: 36vw;
  }

  .educationDegree {
    font-size: 5vw;
  }

  .educationCollege {
    font-size: 4vw;
  }

  .educationDescription {
    font-size: 4.5vw;
  }

  .buttonConvo {
    font-size: 5vw;
  }

  .buttonConvo:hover {
    font-size: 5.5vw;
  }
}

.contentInfoBlogs {
  margin-left: 12%;
  width: 100%;
  text-align: center;
}

.headerTextBlogs {
  font-size: 3vw;
  font-weight: 600;
  font-family: "Righteous", cursive;
  color: #757575;
}

.tagLinePortfolio {
  color: #74c3c8;
  font-size: 1.5vw;
}

.blogsContent {
  margin-left: 5%;
  margin-top: 3%;
  overflow: auto;
  height: 80vh;
}

.sideImageBlogs {
  width: 80%;
  height: 100vh;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .w3-half {
    width: 100% !important;
  }

  .headerTextBlogs {
    font-size: 10vw;
  }

  .tagLinePortfolio {
    font-size: 3.8vw !important;
  }

  .blogsContent {
    margin-left: 0%;
    margin-top: 3%;
  }

  .contentInfoBlogs {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }

  .sideImageBlogs {
    display: none;
  }
}

/* For mobile screens*/
@media screen and (max-width: 600px) {
  .headerTextBlogs {
    font-size: 10vw;
  }

  .tagLinePortfolio {
    font-size: 3.8vw !important;
  }

  .blogsContent {
    margin-left: 0%;
    margin-top: 3%;
  }

  .contentInfoBlogs {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }
}

.blogsCard {
  border-radius: 0.8vw;
  padding: 2% 3%;
  width: 90%;
  margin-top: 3%;
}

.blogPrimaryText {
  font-family: "Ubuntu", sans-serif;
  color: white;
  font-size: 1.8vw;
}

.optionIcons {
  color: white;
  font-size: 1.8vw;
}

.blogSecondaryText {
  font-size: 1.1vw;
  text-align: left;
}

.blogFooter {
  margin-top: 20%;
  width: 100%;
}

.blogImage {
  height: 3vw;
  width: 3.5vw;
  margin-top: 2%;
}

/* All media quiries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .blogsCard {
    width: 100%;
    margin-top: 5%;
  }

  .blogPrimaryText {
    font-size: 5vw;
  }

  .optionIcons {
    font-size: 5vw;
  }

  .blogSecondaryText {
    font-size: 3vw;
  }

  .blogImage {
    height: 10vw;
    width: 15vw;
  }
}

/* For mobile screens*/
@media screen and (max-width: 600px) {
  .blogsCard {
    width: 100%;
    margin-top: 5%;
  }

  .blogPrimaryText {
    font-size: 5vw;
  }

  .optionIcons {
    font-size: 5vw;
  }

  .blogSecondaryText {
    font-size: 3vw;
  }

  .blogImage {
    height: 10vw;
    width: 15vw;
  }
}

.contentInfoHobby {
  margin-left: 12%;
  width: 100%;
  text-align: center;
  height: 100vh;
  max-height: 100vh;
  position: relative;
}

.verticalMiddleHobbies {
  margin: 0;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.headerTextHobby {
  font-size: 3vw;
  font-weight: 600;
  font-family: "Righteous", cursive;
  color: #757575;
}

.tagLineHobby {
  color: #642520;
  font-size: 1.5vw;
}

.contentBody {
  margin-top: 2%;
}

.cardHolder {
  padding: 2% 2%;
}

.sideImageHobby {
  width: 80%;
  min-height: 100%;
  height: 100vh;
  background-image: url("https://s3.us-east-2.amazonaws.com/victordeb.me-files/images/elvis.jpg");
  background-position: center;
  background-size: cover;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .w3-half {
    width: 100% !important;
  }

  .headerTextHobby {
    font-size: 10vw;
  }

  .tagLineHobby {
    font-size: 3.8vw !important;
  }

  .contentInfoHobby {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 2%;
    width: 100%;
  }

  .sideImageHobby {
    display: none;
  }
}

/* For mobile screens*/
@media screen and (max-width: 600px) {
  .headerTextHobby {
    font-size: 10vw;
  }

  .tagLineHobby {
    font-size: 3.8vw !important;
  }

  .contentInfoHobby {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }
}

.image {
  height: 18vh;
  width: 9vw;
}

.hobby {
  color: #e2257d;
  font-size: 1.2vw;
  font-weight: 600;
}

.description {
  color: gray;
  font-size: 0.8vw;
}

.buttonHobbie {
  margin-top: 4%;
  border-radius: 0.5vw;
  font-size: 1vw;
  cursor: pointer;
  color: white;
  border: none;
  padding: 5%;
  font-family: "Spinnaker", sans-serif;
  -webkit-transition: box-shadow 0.3s, font-size 0.3s;
  transition: box-shadow 0.3s, font-size 0.3s;
  background-image: -webkit-linear-gradient(
    left,
    #fd898e,
    #f97a91,
    #f26c96,
    #e95f9d,
    #dd54a5
  );
  background-image: linear-gradient(
    to right,
    #fd898e,
    #f97a91,
    #f26c96,
    #e95f9d,
    #dd54a5
  );
}

.buttonHobbie:hover {
  box-shadow: 0px 20px 15px -10px #f4b5da;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .image {
    height: 15vh;
    width: 27vw;
  }

  .hobby {
    font-size: 2.5vw;
  }

  .description {
    font-size: 1.5vw;
  }

  .buttonHobbie {
    font-size: 2vw;
  }
}

/* For mobile screens*/
@media screen and (max-width: 600px) {
  .image {
    height: 20vh;
    width: 35vw;
  }

  .hobby {
    font-size: 7vw;
  }

  .description {
    font-size: 5vw;
  }

  .buttonHobbie {
    font-size: 5vw;
  }
}

.contentInfoContact {
  margin-left: 12%;
  width: 100%;
  text-align: center;
}

.headerTextContact {
  font-size: 3vw;
  font-weight: 600;
  font-family: "Righteous", cursive;
  color: #757575;
}

.tagLineContact {
  color: #642520;
  font-size: 1.5vw;
}

.commentBox {
  color: white;
  margin-top: 3%;
  margin-left: 10%;
  width: 23vw;
  height: 64vh;
  border-radius: 1vw;
  background-color: #72e79f;
  box-shadow: 0px 30px 10px -20px #b4f3cc;
}

.heading {
  text-align: left;
  /* font-family: "Ubuntu", sans-serif; */
  margin-left: 5%;
  font-size: 2vw;
}

.comment {
  width: 60%;
  text-align: left;
  /* font-family: "Ubuntu", sans-serif; */
  margin-left: 5%;
  font-size: 1.7vw;
}

.overlapBox {
  color: white;
  font-size: 2vw;
  /* font-family: "Ubuntu", sans-serif; */
  width: 30vw;
  height: 33vh;
  border-radius: 1vw;
  background-color: #f77b74;
  position: fixed;
  z-index: 10;
  top: 15.5vw;
  left: 27vw;
  box-shadow: 5px 5px 10px gray;
  padding-left: 1%;
}

.clickCopy {
  cursor: pointer;
  -webkit-transition: font-size 0.1s;
  transition: font-size 0.1s;
}

.clickCopy:active {
  font-size: 1.2vw;
}

.tooltipText {
  position: absolute;
  left: 0;
  bottom: 29px;
  background-color: antiquewhite;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  border-radius: 1vw;
  padding: 5px 5px;
  font-size: 1vw;
  color: #757575;
}

.changeBack {
  background-color: #91e5b1 !important;
  color: white;
}

.socialLinks {
  color: white;
  font-size: 3vw;
  padding-left: 3%;
  -webkit-transform: color 0.3s;
          transform: color 0.3s;
}

.sideImageContact {
  width: 60%;
  min-height: 100%;
  height: 100vh;
  background-image: url("https://s3.us-east-2.amazonaws.com/victordeb.me-files/images/bowie.jpg");
  background-position: center;
  background-size: cover;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .w3-half {
    width: 100% !important;
  }

  .headerTextContact {
    font-size: 10vw;
  }

  .tagLineContact {
    font-size: 3.8vw !important;
  }

  .commentBox {
    margin-top: 3%;
    margin-left: 0%;
    width: 63vw;
    height: 64vh;
  }

  .heading {
    font-size: 7vw;
  }

  .comment {
    font-size: 5vw;
  }

  .overlapBox {
    font-size: 4vw;
    width: 50vw;
    height: 25vh;
    top: 55vw;
    left: 45vw;
    padding-left: 3%;
  }

  .tooltipText {
    font-size: 3vw;
  }

  .socialLinks {
    font-size: 5vw;
    padding-left: 3%;
  }

  .contentInfoContact {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }

  .sideImageContact {
    display: none;
  }
}

/* For mobile screens*/
@media screen and (max-width: 600px) {
  .headerTextContact {
    font-size: 10vw;
  }

  .tagLineContact {
    font-size: 3.8vw !important;
  }

  .conatctBody {
    margin-top: 10%;
  }

  .commentBox {
    margin-top: 3%;
    margin-left: 0%;
    width: 63vw;
    height: 350px;
  }

  .heading {
    font-size: 7vw;
  }

  .comment {
    font-size: 5vw;
  }

  .overlapBox {
    font-size: 4vw;
    width: 50vw;
    height: 130px;
    top: 250px;
    left: 45vw;
    padding-left: 3%;
  }

  .tooltipText {
    font-size: 3vw;
  }

  .socialLinks {
    font-size: 5vw;
    padding-left: 3%;
  }

  .contentInfoContact {
    margin-top: 10%;
    margin-left: 0%;
    padding: 0% 5%;
    width: 100%;
  }
}

