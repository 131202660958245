.main {
  margin-top: 1%;
}

.progressBarParent {
  font-size: 0.9vw;
  background-color: #f1f1f1;
  border-radius: 15px;
  margin-left: 6%;
  margin-top: 1%;
}

.progressBarChild {
  transition: width 2s ease-out;
  font-family: "Fredoka One", cursive;
  color: white;
  border-radius: 15px;
}

.logo {
  height: 2.3vw;
  width: 2.3vw;
}

/* All media queries */

/* for tabs and ipads*/
@media screen and (max-width: 1050px) and (min-width: 601px) {
  .main {
    margin-top: 1.5%;
  }

  .progressBarParent {
    font-size: 2.5vw;
    margin-left: 8%;
    margin-top: 1%;
  }

  .logo {
    height: 5vw;
    width: 5vw;
  }
}

/* For all size of mobile screens, screen width 600px or smaller */

@media screen and (max-width: 600px) {
  .main {
    margin-top: 1.5%;
  }

  .progressBarParent {
    font-size: 2.5vw;
    margin-left: 8%;
    margin-top: 1%;
  }

  .logo {
    height: 5vw;
    width: 5vw;
  }
}
